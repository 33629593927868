import * as React from "react"

const HoraArg = () => {
	const [timeArg, setTimeArg] = React.useState()
	
	function printTimeArg(){
		var date = new Date();
		  //var year = date.getFullYear();
		  //var month = date.getMonth();
		  //var day = date.getDate();
		  //var min = date.getMinutes();
		  //var hour = date.getHours();
		  //var utc = new Date(Date.UTC(year, month, day, hour, min, 0));
		  var utc = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
		  
		  var arg = new Date(utc.getTime() - (3 * 60 * 60 * 1000));
		  //var mia = new Date(utc.getTime() - (5 * 60 * 60 * 1000));
		  
		  var diaArg = arg.toLocaleDateString('es', { weekday: 'long' });
		  diaArg = diaArg.charAt(0).toUpperCase() + diaArg.slice(1);
		  var mesArg = arg.toLocaleDateString('es', { month: 'long' });
		  mesArg = mesArg.charAt(0).toUpperCase() + mesArg.slice(1);
		  
		  setTimeArg(
			"Buenos Aires (GMT-3)<br/>"+diaArg+", "+
							mesArg+" "+
							arg.getDate()+", "+
							arg.getFullYear()+", "+
							arg.getHours()+":"+
							((arg.getMinutes() < 10 ? '0':'')+arg.getMinutes())
		  );
	}
	
	React.useEffect(() => {
  	    printTimeArg();		  
		const interval = setInterval(() => {
		  printTimeArg();		  
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	
	return(
		<span className="timeArg" dangerouslySetInnerHTML={{ __html: timeArg }} />
	)
}

export default HoraArg
